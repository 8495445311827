import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const CTA = makeShortcode("CTA");
const Chocolate = makeShortcode("Chocolate");
const Box = makeShortcode("Box");
const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Aligner center sx={{
      height: ['unset', 'unset', '600px'],
      width: '100%'
    }} mdxType="Aligner">
      <Image src="/images/governance-hero.png" mdxType="Image" />
    </Aligner>
    <p>{`Learn about Decision-Making and Communication among the Stakeholders of MakerDAO`}</p>
    <h2>{`Get Started With Governance`}</h2>
    <p>{`MakerDAO governance describes the way rules, norms, and actions are structured, sustained, regulated, and upheld.`}</p>
    <CTA mdxType="CTA">
      <p>{`Read our guide on `}<a parentName="p" {...{
          "href": "/learn/governance/participate/"
        }}>{`How to Participate.`}</a></p>
      <p>{`Learn more about `}<a parentName="p" {...{
          "href": "/learn/governance/how-voting-works/"
        }}>{`How Voting Works`}</a></p>
      <p>{`Prepare to vote in `}<a parentName="p" {...{
          "href": "/learn/governance/voting-setup/"
        }}>{`Voting Wallet Setup`}</a></p>
      <p>{`Check out useful `}<a parentName="p" {...{
          "href": "/learn/governance/participate"
        }}>{`Tools`}</a></p>
    </CTA>
    <p>{`Governance involves processes conducted both on-chain and off-chain.`}</p>
    <h2>{`On-Chain Governance`}</h2>
    <p>{`On-chain governance refers to Governance Polls and Executive Votes. Anyone who owns MKR can participate in these votes.`}</p>
    <CTA mdxType="CTA">
      <h3>{`Learn more about `}<a parentName="h3" {...{
          "href": "/learn/governance/on-chain-gov/"
        }}>{`On-Chain Governance`}</a></h3>
    </CTA>
    <Chocolate mdxType="Chocolate">
      <Box mdxType="Box">
        <Icon size={"40px"} name="maker" mdxType="Icon" />
        <h4>{`Maker (MKR) Token`}</h4>
        <p>{`Maker (MKR) token holders are the primary stakeholders of the system and vote using the Maker Protocol's on-chain governance system. `}</p>
        <p><a parentName="p" {...{
            "href": "/learn/governance/mkr-token.mdx/"
          }}>{`MKR Token`}</a></p>
      </Box>
      <Box mdxType="Box">
        <Icon size={"40px"} name="forum" mdxType="Icon" />
        <h4>{`Governance Polls`}</h4>
        <p>{`Governance Polls establish a soft consensus and measure community sentiment, through time-based voting.`}</p>
        <p><a parentName="p" {...{
            "href": "/learn/governance/how-voting-works#governance-polls-and-executive-votes"
          }}>{`Learn about Governance Polls`}</a></p>
      </Box>
      <Box mdxType="Box">
        <Icon size={"40px"} name="clipboard" mdxType="Icon" />
        <h4>{`Executive Votes`}</h4>
        <p>{`Executive Votes change the state of the system through through `}<a parentName="p" {...{
            "href": "/learn/governance/how-voting-works#continuous-approval-voting"
          }}>{`continuous approval voting`}</a>{`.`}</p>
        <p><a parentName="p" {...{
            "href": "/learn/governance/how-voting-works/#governance-polls-and-executive-votes"
          }}>{`Learn about Executive Proposals and Votes`}</a></p>
      </Box>
    </Chocolate>
    <h2>{`Off-Chain Governance`}</h2>
    <p>{`Off-chain governance refers to processes for making decisions that don't require on-chain voting and gathering feedback prior to on-chain voting. Anyone can participate in off-chain governance.`}</p>
    <CTA mdxType="CTA">
      <h3>{`Learn more about `}<a parentName="h3" {...{
          "href": "/learn/governance/off-chain-gov/"
        }}>{`Off-Chain Governance`}</a></h3>
    </CTA>
    <Chocolate mdxType="Chocolate">
      <Box mdxType="Box">
        <Icon size={"40px"} name="people" mdxType="Icon" />
        <h4>{`Meetings`}</h4>
        <p><a parentName="p" {...{
            "href": "/learn/governance/gov-risk-framework/"
          }}>{`Governance and Risk Meeting`}</a></p>
      </Box>
      <Box mdxType="Box">
        <Icon size={"40px"} name="wrench" mdxType="Icon" />
        <h4>{`Risk`}</h4>
        <p><a parentName="p" {...{
            "href": "/learn/governance/gov-risk-framework/"
          }}>{`Governance Risk Framework`}</a></p>
        <p><a parentName="p" {...{
            "href": "/learn/governance/emergency-shutdown/"
          }}>{`Emergency Shutdown`}</a></p>
      </Box>
      <Box mdxType="Box">
        <Icon size={"40px"} name="question" mdxType="Icon" />
        <h4>{`Discussion`}</h4>
        <p><a parentName="p" {...{
            "href": "https://forum.makerdao.com/"
          }}>{`Forum`}</a></p>
        <p><a parentName="p" {...{
            "href": "https://chat.makerdao.com/"
          }}>{`Maker Chat`}</a></p>
        <p><a parentName="p" {...{
            "href": "/learn/governance/discussion/"
          }}>{`Frequent Discussion Topics`}</a></p>
      </Box>
    </Chocolate>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      